import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { CurrencyDropDown, Invoice, InvoiceExpenseType } from 'openapi';

import { useTranslations } from 'context/TranslationContext';

import { DATE_FORMATS } from 'utils/constants/constants';
import { processMoneyInput } from 'utils/helpers/moneyHelper';

import {
  fullWidth,
  verificationFormFieldsWrapper,
  verificationFormItem,
  verificationFormItemNoHeight
} from 'styles/pages/InvoiceVerificationStyle';

interface InvoiceFormSectionProps {
  expenseTypeOptions: InvoiceExpenseType[];
  currencies: CurrencyDropDown[];
  currentInvoice: Invoice;
}

export const InvoiceFormSection = ({
  expenseTypeOptions,
  currencies,
  currentInvoice
}: InvoiceFormSectionProps) => {
  const { control } = useFormContext();
  const { translate } = useTranslations();

  const [isInvoiceDateOpen, setInvoiceDateOpen] = useState(false);

  return (
    <Box sx={fullWidth}>
      <Box
        sx={{
          ...verificationFormFieldsWrapper,
          height: '55px'
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Controller
            name="invoiceDate"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <FormControl error={!!error} sx={verificationFormItem}>
                  <DatePicker
                    sx={{ width: '100%' }}
                    format={DATE_FORMATS.displayedDateFormat}
                    open={isInvoiceDateOpen}
                    disableFuture
                    label={`${translate('labels.invoiceDate')}*`}
                    value={
                      value
                        ? moment(value, DATE_FORMATS.displayedDateFormat)
                        : null
                    }
                    onClose={() => setInvoiceDateOpen(false)}
                    onChange={(event) => {
                      onChange(
                        (event as Moment).format(
                          DATE_FORMATS.displayedDateFormat
                        )
                      );
                    }}
                    slotProps={{
                      textField: {
                        error: !!error,
                        helperText: !!error && translate('errors.invoiceDate'),
                        name: 'invoiceDate',
                        onClick: () => setInvoiceDateOpen(true),
                        inputProps: { readOnly: true }
                      },
                      openPickerButton: {
                        onClick: () => setInvoiceDateOpen(true)
                      }
                    }}
                  />
                </FormControl>
              );
            }}
          />
        </LocalizationProvider>
        <Controller
          name="invoiceAmount"
          control={control}
          rules={{
            required: true
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              onChange={(event) => {
                field.onChange(
                  processMoneyInput(event.target.value, String(field.value))
                );
              }}
              label={`${translate('labels.amount')}*`}
              name="invoiceAmount"
              error={!!error}
              placeholder={translate('labels.amount')}
              sx={verificationFormItemNoHeight}
            />
          )}
        />
        <Controller
          name="currencyId"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <FormControl error={!!error} sx={verificationFormItemNoHeight}>
              <InputLabel>{`${translate('labels.currency')}*`}</InputLabel>
              <Select
                {...field}
                value={field.value || ''}
                sx={{ textAlign: 'left' }}
                label={`${translate('labels.currency')}*`}
                error={!!error}
              >
                {currencies &&
                  currencies.map((currency: CurrencyDropDown) => (
                    <MenuItem key={currency.id} value={currency.id}>
                      {currency.isoCode}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>
                {error && translate('errors.currency')}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '58px'
        }}
      >
        <Controller
          name="isAlreadyPaid"
          control={control}
          render={({ field }) => (
            <Box display="flex" alignItems="center">
              <Typography sx={{ fontSize: '0.8rem' }}>
                {translate('labels.alreadyPaid')}
              </Typography>
              <Checkbox
                size="medium"
                defaultChecked={currentInvoice.isAlreadyPaid}
                {...field}
              />
            </Box>
          )}
        />
      </Box>
      <Box
        sx={{
          ...verificationFormFieldsWrapper,
          minHeight: '50px',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            ...verificationFormFieldsWrapper,
            minHeight: '50px'
          }}
        >
          <Controller
            name="expenseType"
            control={control}
            rules={{
              required: translate('errors.expenseType')
            }}
            render={({ field, fieldState: { error } }) => (
              <FormControl error={!!error} sx={verificationFormItem}>
                <InputLabel>{`${translate('labels.expenseType')}*`}</InputLabel>
                <Select
                  {...field}
                  value={field.value || ''}
                  sx={{ textAlign: 'left' }}
                  label={`${translate('labels.expenseType')}*`}
                  error={!!error}
                  renderValue={(selected) => {
                    const selectedOption = expenseTypeOptions.find(
                      (expenseType) => expenseType.id === Number(selected)
                    ) as InvoiceExpenseType;

                    return (
                      <span
                        style={{
                          color:
                            selectedOption && !selectedOption.isActive
                              ? 'gray'
                              : 'inherit'
                        }}
                      >
                        {!selectedOption.isActive
                          ? `${selectedOption.name} ${translate(
                              'labels.deactivatedExpenseTypeValue'
                            )}`
                          : selectedOption.name}
                      </span>
                    );
                  }}
                >
                  {expenseTypeOptions &&
                    expenseTypeOptions.map(
                      (expenseType: InvoiceExpenseType) =>
                        expenseType.isActive && (
                          <MenuItem
                            key={expenseType.id}
                            value={expenseType.id}
                            disabled={!expenseType.isActive}
                          >
                            {expenseType.name}
                          </MenuItem>
                        )
                    )}
                </Select>
                <FormHelperText>
                  {error && translate('errors.expenseType')}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

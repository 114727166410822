import SearchIcon from '@mui/icons-material/Search';
import StarRateIcon from '@mui/icons-material/StarRate';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { yellow } from '@mui/material/colors';
import {
  useEffect,
  useMemo,
  useState,
  useCallback,
  SyntheticEvent,
  Dispatch,
  SetStateAction
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';
import { useCounterPartyController } from 'api/controllers/CounterPartyController';

import { CounterpartyDropDown } from 'openapi';

import { useCompanies } from 'context/CompanyContext';
import { useTranslations } from 'context/TranslationContext';

import { useDebounce } from 'hooks/useDebounce';

import { COUNTERPARTY_SEARCH_MIN_CHARACTERS } from 'utils/constants/invoices';
import {
  getCounterPartySearchOptionLabel,
  validateRegistrationNumber,
  validateVatNumber
} from 'utils/helpers/invoiceHelpers';

import {
  fullWidth,
  verificationFormFields,
  verificationFormFieldsWrapper,
  verificationFormItem
} from 'styles/pages/InvoiceVerificationStyle';

interface CounterpartyFormSectionProps {
  setSelectedCounterPartyId: Dispatch<SetStateAction<number | null>>;
  setIsExisting: Dispatch<SetStateAction<boolean>>;
  setCpIsNotFoundInDatabase: Dispatch<React.SetStateAction<boolean>>;
}

export const CounterpartyFormSection = ({
  setSelectedCounterPartyId,
  setIsExisting,
  setCpIsNotFoundInDatabase
}: CounterpartyFormSectionProps) => {
  const { control, setValue, clearErrors, watch, getValues, formState } =
    useFormContext();
  const { translate } = useTranslations();
  const { companyId } = useParams();
  const companyName = useCompanies().companiesList.find((company) => {
    return company.id === Number(companyId);
  })?.name;

  const { checkIfCounterpartyExists } = useCounterPartyController();
  const { getAllDropDownCounterpartiesByCompany } =
    useAdditionalFieldsController();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<CounterpartyDropDown[]>([]);
  const [searchParams, setSearchParams] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFoundInDatabase, setIsFoundInDatabase] = useState<boolean>(true);

  const notEnoughCharactersToSearch = useMemo(() => {
    return (searchParams || '').length < COUNTERPARTY_SEARCH_MIN_CHARACTERS;
  }, [searchParams]);

  const getCounterParties = useCallback(async () => {
    if (notEnoughCharactersToSearch) {
      setIsLoading(false);
      setOptions([]);
      return;
    }
    const result = await getAllDropDownCounterpartiesByCompany(
      searchParams,
      Number(companyId)
    );
    setOptions(result);
    setIsLoading(false);
  }, [
    getAllDropDownCounterpartiesByCompany,
    searchParams,
    notEnoughCharactersToSearch
  ]);

  const debouncedSearch = useDebounce(getCounterParties, 500);

  useEffect(() => {
    setIsLoading(true);
    debouncedSearch();
  }, [searchParams, debouncedSearch]);

  const watchedCounterpartyRegistrationNumber = watch(
    'counterpartyRegistrationNumber'
  );
  const watchedCounterpartyVatNumber = watch('counterpartyVatNumber');
  const watchedCounterpartyName = watch('counterpartyName');

  const isCounterpartyRegistrationNumberValid = useMemo(
    () =>
      validateRegistrationNumber(
        watchedCounterpartyVatNumber,
        watchedCounterpartyRegistrationNumber
      ),
    [watchedCounterpartyRegistrationNumber, watchedCounterpartyVatNumber]
  );

  const isCounterpartyVatValid = useMemo(
    () =>
      validateVatNumber(
        watchedCounterpartyVatNumber,
        watchedCounterpartyRegistrationNumber
      ),
    [
      watchedCounterpartyVatNumber,
      watchedCounterpartyRegistrationNumber,
      isCounterpartyRegistrationNumberValid
    ]
  );

  const handleAutocompleteChange = useCallback(
    (value: string, event?: SyntheticEvent<Element, Event>) => {
      let counterpartyName;
      const mappedOptions = options.map((option) => ({
        ...option,
        name: getCounterPartySearchOptionLabel(option)
      }));
      if (event) {
        const arrayIndex = mappedOptions.findIndex(
          (option) => option.name === (event.target as HTMLInputElement).value
        );
        counterpartyName = options[arrayIndex]?.name;
      } else {
        const arrayIndex = mappedOptions.findIndex(
          (option) => option.name === value
        );
        counterpartyName = options[arrayIndex]?.name;
      }

      const selectedOption = options.find(
        (option: CounterpartyDropDown) => option.name === counterpartyName
      );

      if (!selectedOption) {
        return;
      }

      setSelectedCounterPartyId(selectedOption.id || null);
      setValue('counterpartyName', selectedOption.name || '');
      setValue(
        'counterpartyRegistrationNumber',
        selectedOption.registrationNumber || ''
      );
      setValue('counterpartyVatNumber', selectedOption.vatNumber || '');
      clearErrors();
    },
    [options, setSelectedCounterPartyId, setValue, clearErrors]
  );

  const checkExisting = useCallback(async () => {
    if (!companyId) {
      return;
    }

    const response = await checkIfCounterpartyExists(
      Number(companyId),
      getValues('counterpartyName') || '',
      getValues('counterpartyRegistrationNumber') || '',
      getValues('counterpartyVatNumber') || ''
    );

    setIsFoundInDatabase(response.isFoundInDatabase);
    setCpIsNotFoundInDatabase(!response.isFoundInDatabase);
    setIsExisting(response.isCompanyCounterparty);
  }, [getValues, checkIfCounterpartyExists]);

  const debouncedCheckExisting = useDebounce(checkExisting, 200);

  useEffect(() => {
    debouncedCheckExisting();
  }, [
    watchedCounterpartyName,
    watchedCounterpartyRegistrationNumber,
    watchedCounterpartyVatNumber,
    getValues
  ]);

  return (
    <Box sx={fullWidth}>
      <Box sx={verificationFormFields}>
        <Box
          sx={{
            ...verificationFormFieldsWrapper,
            height: '55px'
          }}
        >
          <Controller
            name="counterpartyId"
            control={control}
            render={({ field }) => (
              <FormControl sx={verificationFormItem}>
                <Autocomplete
                  open={open}
                  disableClearable
                  blurOnSelect
                  clearOnBlur={false}
                  forcePopupIcon={false}
                  onInputChange={(event, value) => {
                    handleAutocompleteChange(value);
                  }}
                  onSelect={(event: SyntheticEvent) => {
                    handleAutocompleteChange('', event);
                  }}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) =>
                    getCounterPartySearchOptionLabel(option)
                  }
                  options={options}
                  loading={notEnoughCharactersToSearch ? false : isLoading}
                  noOptionsText={
                    notEnoughCharactersToSearch
                      ? translate('messages.searchRequiredCharacters', {
                          numberOfCharacters: String(
                            COUNTERPARTY_SEARCH_MIN_CHARACTERS
                          )
                        })
                      : translate('labels.noCounterpartiesFound')
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...field}
                      label={translate('labels.counterparty')}
                      onChange={(event) => {
                        setSearchParams(event.target.value);
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment sx={{ ml: 1 }} position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                  renderOption={(props, option) => (
                    <Tooltip
                      key={props.key}
                      title={
                        option.isCompanyCounterparty &&
                        `${translate('messages.counterpartyOf', {
                          companyName: companyName || ''
                        })}`
                      }
                    >
                      <li {...props}>
                        {getCounterPartySearchOptionLabel(option)}
                        {option.isCompanyCounterparty && (
                          <StarRateIcon
                            color="primary"
                            sx={{ ml: 1, mb: 0.5 }}
                          />
                        )}
                      </li>
                    </Tooltip>
                  )}
                />
              </FormControl>
            )}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            opacity: !isFoundInDatabase && formState.isValid ? 1 : 0
          }}
        >
          <WarningIcon
            sx={{
              fontSize: 26,
              color: yellow[900]
            }}
          />
          <Typography fontSize="0.8rem">
            {translate('messages.counterpartyNotInDatabase')}
          </Typography>
        </Box>
        <Box
          sx={{
            ...verificationFormFieldsWrapper,
            minHeight: '50px'
          }}
        >
          <FormControl sx={verificationFormItem}>
            <Controller
              name="counterpartyName"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={`${translate('labels.name')}*`}
                  name="counterpartyName"
                  placeholder={translate('labels.name')}
                  error={!!error}
                  helperText={error && translate('errors.counterpartyName')}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            ...verificationFormFieldsWrapper,
            height: 85
          }}
        >
          <Controller
            name="counterpartyRegistrationNumber"
            control={control}
            rules={{
              validate: () => {
                if (!watchedCounterpartyVatNumber) {
                  return (
                    isCounterpartyRegistrationNumberValid ||
                    translate('errors.counterpartyRegistrationNumberBG')
                  );
                }
                if (watchedCounterpartyVatNumber.startsWith('BG')) {
                  return (
                    isCounterpartyRegistrationNumberValid ||
                    translate('errors.counterpartyRegistrationNumberBG')
                  );
                }

                return (
                  watchedCounterpartyVatNumber === '' ||
                  watchedCounterpartyRegistrationNumber === ''
                );
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!isCounterpartyRegistrationNumberValid}
                label={translate('labels.companyRegistrationNumber')}
                name="counterpartyRegistrationNumber"
                placeholder={translate('labels.counterpartyRegistrationNumber')}
                helperText={
                  !isCounterpartyRegistrationNumberValid &&
                  // eslint-disable-next-line no-nested-ternary
                  (watchedCounterpartyVatNumber?.startsWith('BG')
                    ? translate('errors.counterpartyRegistrationNumberBG')
                    : watchedCounterpartyVatNumber === ''
                    ? translate('errors.counterpartyRegistrationNumberBG')
                    : translate('errors.counterpartyRegistrationNumberForeign'))
                }
                sx={verificationFormItem}
              />
            )}
          />
          <Controller
            name="counterpartyVatNumber"
            control={control}
            rules={{
              validate: (value) => {
                if (value?.startsWith('BG')) {
                  return (
                    isCounterpartyVatValid ||
                    translate('errors.counterpartyVatNumberEquality')
                  );
                }
                return true; // No validation error for non-BG VAT numbers
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label={translate('labels.vatNumber')}
                name="counterpartyVatNumber"
                placeholder={translate('labels.vatNumber')}
                sx={verificationFormItem}
                error={!!field.value && !isCounterpartyVatValid}
                helperText={
                  field.value &&
                  !isCounterpartyVatValid &&
                  translate('errors.counterpartyVatNumberEquality')
                }
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

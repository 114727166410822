import SearchIcon from '@mui/icons-material/Search';
import StarRateIcon from '@mui/icons-material/StarRate';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { yellow } from '@mui/material/colors';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useAdditionalFieldsController } from 'api/controllers/AdditionalFieldsController';
import { useCounterPartyController } from 'api/controllers/CounterPartyController';

import {
  CounterpartyDropDown,
  Invoice,
  InvoicePatch,
  InvoiceSection
} from 'openapi';

import { useCompanies } from 'context/CompanyContext';
import { useTranslations } from 'context/TranslationContext';

import { useDebounce } from 'hooks/useDebounce';

import { COUNTERPARTY_SEARCH_MIN_CHARACTERS } from 'utils/constants/invoices';
import {
  getCounterPartySearchOptionLabel,
  getSupplierDataFormValues,
  getSupplierDefaultValues,
  validateRegistrationNumber,
  validateVatNumber
} from 'utils/helpers/invoiceHelpers';
import { InvoiceSectionKey } from 'utils/interfaces/InvoiceProps';

import {
  verificationFormFields,
  verificationFormFieldsWrapper,
  verificationFormItem
} from 'styles/pages/InvoiceVerificationStyle';

import { ActionButtons } from './ActionButtons';

interface SupplierFormProps {
  currentInvoice: Invoice;
  handleVerify: (values: Invoice, section: InvoiceSectionKey) => void;
  handleBack: (section: InvoiceSection) => void;
  handleGoToMiddleStep: () => void;
  saveInvoiceSection: (
    values: InvoicePatch,
    section: InvoiceSectionKey
  ) => Promise<void>;
  setCounterpartyFormData: React.Dispatch<
    React.SetStateAction<Invoice | undefined>
  >;
  setCpIsNotFoundInDatabase: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SupplierForm = ({
  currentInvoice,
  handleVerify,
  handleBack,
  handleGoToMiddleStep,
  saveInvoiceSection,
  setCounterpartyFormData,
  setCpIsNotFoundInDatabase
}: SupplierFormProps) => {
  const { translate } = useTranslations();
  const { checkIfCounterpartyExists } = useCounterPartyController();
  const { getAllDropDownCounterpartiesByCompany } =
    useAdditionalFieldsController();
  const companyName = useCompanies().companiesList.find(
    (company) => company.id === currentInvoice.companyId
  )?.name;

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<CounterpartyDropDown[]>([]);
  const [searchParams, setSearchParams] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCounterPartyId, setSelectedCounterPartyId] = useState<
    number | null
  >(null);
  const [isExisting, setIsExisting] = useState<boolean>(true);
  const [isFoundInDatabase, setIsFoundInDatabase] = useState<boolean>(true);

  const notEnoughCharactersToSearch = useMemo(() => {
    return (searchParams || '').length < COUNTERPARTY_SEARCH_MIN_CHARACTERS;
  }, [searchParams]);

  const getCounterParties = useCallback(async () => {
    if (notEnoughCharactersToSearch) {
      setIsLoading(false);
      setOptions([]);
      return;
    }
    const result = await getAllDropDownCounterpartiesByCompany(
      searchParams,
      Number(currentInvoice.companyId)
    );
    setOptions(result);
    setIsLoading(false);
  }, [getAllDropDownCounterpartiesByCompany, searchParams, setOptions]);

  const debouncedSearch = useDebounce(getCounterParties, 500);

  useEffect(() => {
    setIsLoading(true);
    debouncedSearch();
  }, [searchParams, debouncedSearch]);

  const {
    control,
    formState,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    watch,
    setError
  } = useForm<Invoice>({
    mode: 'onChange',
    defaultValues: getSupplierDefaultValues(currentInvoice)
  });

  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  const handleVerifySection = () => {
    handleVerify(
      {
        counterpartyId: selectedCounterPartyId || currentInvoice.counterpartyId,
        counterpartyName: getValues('counterpartyName'),
        counterpartyRegistrationNumber: getValues(
          'counterpartyRegistrationNumber'
        ),
        counterpartyVatNumber: getValues('counterpartyVatNumber'),
        ibanList: getValues('ibanList')?.filter((iban) => iban.iban)
      },
      InvoiceSection.SUPPLIER
    );
  };

  const watchedCounterpartyRegistrationNumber = watch(
    'counterpartyRegistrationNumber'
  );
  const watchedCounterpartyVatNumber = watch('counterpartyVatNumber');

  // Validation logic for the registration number and vat number
  const isCounterpartyRegistrationNumberValid = useMemo(
    () =>
      validateRegistrationNumber(
        watchedCounterpartyVatNumber,
        watchedCounterpartyRegistrationNumber
      ),
    [watchedCounterpartyRegistrationNumber, watchedCounterpartyVatNumber]
  );

  const isCounterpartyVatValid = useMemo(
    () =>
      validateVatNumber(
        watchedCounterpartyVatNumber,
        watchedCounterpartyRegistrationNumber
      ),
    [
      watchedCounterpartyVatNumber,
      watchedCounterpartyRegistrationNumber,
      isCounterpartyRegistrationNumberValid
    ]
  );

  const handleAutocompleteChange = useCallback(
    (value: string, event?: SyntheticEvent<Element, Event>) => {
      let counterpartyName;
      if (event) {
        const [inputValue] = (event.target as HTMLInputElement).value.split(
          ' ('
        );
        counterpartyName = inputValue;
      } else {
        const [inputValue] = value.split(' ('); // Remove the registration number/VAT from the name;
        counterpartyName = inputValue;
      }

      const selectedOption = options.find(
        (option: CounterpartyDropDown) => option.name === counterpartyName
      );

      if (!selectedOption) {
        return;
      }

      setSelectedCounterPartyId(selectedOption.id || null);
      setValue('counterpartyName', selectedOption.name || '');
      setValue(
        'counterpartyRegistrationNumber',
        selectedOption.registrationNumber || ''
      );
      setValue('counterpartyVatNumber', selectedOption.vatNumber || '');
      clearErrors();
    },
    [options, setSelectedCounterPartyId, setValue, clearErrors]
  );

  useEffect(() => {
    const counterpartyName = getValues('counterpartyName');
    // TODO: CHANGE THIS TO WORK WITH REACT HOOK FORM !!!
    if (!counterpartyName) {
      setError('counterpartyName', {
        type: 'required'
      });
    }
  }, [getValues, setError]);

  useEffect(() => {
    setIsFormValid(formState.isValid);
  }, [formState.isValid]);

  const watchedCounterpartyName = watch('counterpartyName');

  const checkExisting = useCallback(async () => {
    if (!currentInvoice.companyId) {
      return;
    }

    const response = await checkIfCounterpartyExists(
      currentInvoice.companyId,
      getValues('counterpartyName') || '',
      getValues('counterpartyRegistrationNumber') || '',
      getValues('counterpartyVatNumber') || ''
    );

    setIsExisting(response.isCompanyCounterparty);
    setIsFoundInDatabase(response.isFoundInDatabase);
    setCpIsNotFoundInDatabase(!response.isFoundInDatabase);
  }, [
    getValues('counterpartyName'),
    getValues('counterpartyRegistrationNumber'),
    getValues('counterpartyVatNumber'),
    checkIfCounterpartyExists
  ]);

  const debouncedCheckExisting = useDebounce(checkExisting, 200);

  useEffect(() => {
    debouncedCheckExisting();
  }, [
    watchedCounterpartyName,
    getValues('counterpartyName'),
    getValues('counterpartyRegistrationNumber'),
    getValues('counterpartyVatNumber')
  ]);

  const handleNextStep = () => {
    handleGoToMiddleStep();
    saveInvoiceSection(
      getSupplierDataFormValues(
        currentInvoice,
        isExisting,
        selectedCounterPartyId,
        getValues
      ),
      InvoiceSection.SUPPLIER
    );
    setCounterpartyFormData({
      counterpartyId: selectedCounterPartyId || currentInvoice.counterpartyId,
      counterpartyName: getValues('counterpartyName'),
      counterpartyRegistrationNumber: getValues(
        'counterpartyRegistrationNumber'
      ),
      counterpartyVatNumber: getValues('counterpartyVatNumber'),
      ibanList: getValues('ibanList')?.filter((iban) => iban.iban)
    });
  };

  return (
    <Box sx={{ pt: 2.5 }}>
      <form
        className="supplier-form"
        onSubmit={handleSubmit(
          isExisting ? handleVerifySection : handleNextStep
        )}
      >
        <ActionButtons
          handleBack={() => handleBack(InvoiceSection.RECEIVER)}
          isFormValid={isFormValid}
        />
        <Box sx={verificationFormFields}>
          <Box
            sx={{
              ...verificationFormFieldsWrapper,
              height: '55px'
            }}
          >
            <Controller
              name="counterpartyId"
              control={control}
              render={({ field }) => (
                <FormControl sx={verificationFormItem}>
                  <Autocomplete
                    open={open}
                    disableClearable
                    blurOnSelect
                    clearOnBlur={false}
                    forcePopupIcon={false}
                    onInputChange={(event, value) => {
                      handleAutocompleteChange(value);
                    }}
                    onSelect={(event: SyntheticEvent) => {
                      handleAutocompleteChange('', event);
                    }}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    getOptionLabel={(option) =>
                      getCounterPartySearchOptionLabel(option)
                    }
                    options={options}
                    loading={notEnoughCharactersToSearch ? false : isLoading}
                    noOptionsText={
                      notEnoughCharactersToSearch
                        ? translate('messages.searchRequiredCharacters', {
                            numberOfCharacters: String(
                              COUNTERPARTY_SEARCH_MIN_CHARACTERS
                            )
                          })
                        : translate('labels.noCounterpartiesFound')
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...field}
                        label={translate('labels.counterparty')}
                        onChange={(event) => {
                          setSearchParams(event.target.value);
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment sx={{ ml: 1 }} position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              {isLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Tooltip
                        key={props.key}
                        title={
                          option.isCompanyCounterparty &&
                          `${translate('messages.counterpartyOf', {
                            companyName: companyName || ''
                          })}`
                        }
                      >
                        <li {...props}>
                          {getCounterPartySearchOptionLabel(option)}
                          {option.isCompanyCounterparty && (
                            <StarRateIcon
                              color="primary"
                              sx={{ ml: 1, mb: 0.5 }}
                            />
                          )}
                        </li>
                      </Tooltip>
                    )}
                  />
                </FormControl>
              )}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
              opacity: !isFoundInDatabase && isFormValid ? 1 : 0
            }}
          >
            <WarningIcon
              sx={{
                fontSize: 26,
                color: yellow[900]
              }}
            />
            <Typography fontSize="0.8rem">
              {translate('messages.counterpartyNotInDatabase')}
            </Typography>
          </Box>
          <Box
            sx={{
              ...verificationFormFieldsWrapper,
              minHeight: '50px'
            }}
          >
            <FormControl sx={verificationFormItem}>
              <Controller
                name="counterpartyName"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label={`${translate('labels.name')}*`}
                    name="counterpartyName"
                    placeholder={translate('labels.name')}
                    error={!!error}
                    helperText={error && translate('errors.counterpartyName')}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              ...verificationFormFieldsWrapper,
              height: 85
            }}
          >
            <Controller
              name="counterpartyRegistrationNumber"
              control={control}
              rules={{
                validate: () => {
                  if (!watchedCounterpartyVatNumber) {
                    return (
                      isCounterpartyRegistrationNumberValid ||
                      translate('errors.counterpartyRegistrationNumberBG')
                    );
                  }
                  if (watchedCounterpartyVatNumber.startsWith('BG')) {
                    return (
                      isCounterpartyRegistrationNumberValid ||
                      translate('errors.counterpartyRegistrationNumberBG')
                    );
                  }

                  return (
                    watchedCounterpartyVatNumber === '' ||
                    watchedCounterpartyRegistrationNumber === ''
                  );
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!isCounterpartyRegistrationNumberValid}
                  label={translate('labels.companyRegistrationNumber')}
                  name="counterpartyRegistrationNumber"
                  placeholder={translate(
                    'labels.counterpartyRegistrationNumber'
                  )}
                  helperText={
                    !isCounterpartyRegistrationNumberValid &&
                    // eslint-disable-next-line no-nested-ternary
                    (watchedCounterpartyVatNumber?.startsWith('BG')
                      ? translate('errors.counterpartyRegistrationNumberBG')
                      : watchedCounterpartyVatNumber === ''
                      ? translate('errors.counterpartyRegistrationNumberBG')
                      : translate(
                          'errors.counterpartyRegistrationNumberForeign'
                        ))
                  }
                  sx={verificationFormItem}
                />
              )}
            />
            <Controller
              name="counterpartyVatNumber"
              control={control}
              rules={{
                validate: (value) => {
                  if (value?.startsWith('BG')) {
                    return (
                      isCounterpartyVatValid ||
                      translate('errors.counterpartyVatNumberEquality')
                    );
                  }
                  return true; // No validation error for non-BG VAT numbers
                }
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={translate('labels.vatNumber')}
                  name="counterpartyVatNumber"
                  placeholder={translate('labels.vatNumber')}
                  sx={verificationFormItem}
                  error={!!field.value && !isCounterpartyVatValid}
                  helperText={
                    field.value &&
                    !isCounterpartyVatValid &&
                    translate('errors.counterpartyVatNumberEquality')
                  }
                />
              )}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};
